@white: #fff;
@grey: #9b9b9b;
@blue: #0095ff;
@darkblue: #0065cf;
@fontcolor: #788090;
@checkbox: #868686;
@tablerow: #dddddd; /*#ededed;*/
@tableborder: #cccccc;
@boxgrey: #ededed; /*#f7f7f9;*/
@selectborder: #c8c8c8;
@selectoritemhover: #eaeaea;
@sorthover: #333;
@error: #ff4747;
@disabled: #aaa;
@disabled20: #ddd;
@outline: rgba(0, 150, 255, .4);
@red: #ff4747;
@cellError: rgba(255, 71, 71, .33);
@green: #7ed321;
@darkgreen: #6eb51c;
@yellow: #f5cc23;
@liteyellow: #f2de91;
@lightred: #ef8f8f;
@orange: #eb8300;
@cyan: #00FFFF;
@magenta: #FF00FF;

@navHoverTiming: .6s;

@boxBorderRadius: 3px;
@inputBorderRadius: 5px;
@inputHeight: 45px;
@inputHeightInnerLabel: 43px;

@fs10: .625rem;
@fs12: .75rem;
@fs14: .875rem;
@fs15: .9375rem;
@fs16: 1rem;
@fs17: 1.0625rem;
@fs18: 1.125rem;
@fs20: 1.25rem;
@fs22: 1.375rem;
@fs24: 1.5rem;
@fs26: 1.625rem;
@fs29: 1.8125rem;
@fs32: 2rem;
@fs43: 2.6875rem;
@fs48: 3rem;

@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?54812699');
  src: url('../fonts/fontello.eot?54812699#iefix') format('embedded-opentype'), url('../fonts/fontello.woff2?54812699') format('woff2'), url('../fonts/fontello.woff?54812699') format('woff'), url('../fonts/fontello.ttf?54812699') format('truetype'), url('../fonts/fontello.svg?54812699#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Lato, Verdana, Arial, Helvetica, sans-serif;
  color: @fontcolor;
}

html {
  height: 100%;
  font-size: 16px;
}

body {
  position: relative;
  font-size: @fs14;
  line-height: 1.4;
  background: @white;
  height: 100%;
  overflow-y: scroll;
}

app-root {
  display: block;
  height: 100%;
}

.required {
  background-image: url(/assets/required.png);
  background-position: right 20px top 50%;
  background-repeat: no-repeat;
  padding-right: 45px;
}

#loading,
#cover,
.cover,
.p-datatable-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex !important;
  align-items: center;
  background-color: rgba(255, 255, 255, .8);
  opacity: 0;
  display: none;
  pointer-events: none;
  transition: opacity .5s;

  :before {
    content: "";
    position: absolute;
    width: 200px;
    height: 200px;
    top: calc(~'50% - 100px');
    left: calc(~'50% - 100px');
    z-index: -1;
    background: url(/assets/loading.svg) center center no-repeat;
    animation: loading 6s linear infinite;
  }


  > div {
    margin: auto;
    text-align: center;
    width: 140px;
  }

  &.show {
    opacity: 1;
    pointer-events: all;
    transition: opacity .6s;
  }

  &.small {
    :before {
      width: 100px;
      height: 100px;
      top: calc(~'50% - 50px');
      left: calc(~'50% - 50px');
    }

    > div {
      width: 70px;
      font-size: @fs12;
    }
  }
}

.p-datatable-loading-overlay {
  :before {
    width: 100px;
    height: 100px;
    top: calc(~'50% - 50px');
    left: calc(~'50% - 50px');
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

[data-icon]:before {
  display: inline-block;
  margin: 0 5px;
  font-family: fontello;
  font-weight: normal;
  content: attr(data-icon);
  speak: none;
  vertical-align: middle;
}

.first[data-icon]:before {
  margin: 0 10px 0 0;
}

.asInput[data-icon]:before {
  margin: -1px 14px 1px 4px;
}

.fs10 {
  font-size: @fs10;
}

.fs12 {
  font-size: @fs12;
}

.fs14 {
  font-size: @fs14;
}

.fs16 {
  font-size: @fs16;
}

.fs17 {
  font-size: @fs17;
}

.fs18 {
  font-size: @fs18;
}

.fs20 {
  font-size: @fs20;
}

.fs22 {
  font-size: @fs22;
}

.fs24 {
  font-size: @fs24;
}

.fs26 {
  font-size: @fs26;
}

.fs29 {
  font-size: @fs29;
}

.fs32 {
  font-size: @fs32;
}

.fs48 {
  font-size: @fs48;
}

.pi {
  font-size: 1.25em;
}

.blue {
  color: @blue !important;
}

.black {
  color: @fontcolor !important;
}

.red {
  color: @red !important;
}

.yellow {
  color: @yellow !important;
}

.orange {
  color: @orange !important;
}

.green {
  color: @green !important;
}

.darkgreen {
  color: @darkgreen !important;
}

.grey {
  color: @disabled !important;
}

.magenta {
  color: @magenta !important;
}

.cyan {
  color: @cyan !important;
}

.h2Icon {
  display: inline-block;
  margin: 2px 0 0 5px;
}

.sepa {
  margin: 0 0 0 5px;
  font-size: @fs10;
}

.iconAttention {
  float: right;
  color: @red;
  font-size: @fs20;
  margin: 0 0 0 -2px;
}

.preview {
  max-width: 100%;
  margin: 2px 0 5px;
  border: @disabled 1px dashed;
}

.invisible {
  visibility: hidden;
}

.min {
  width: 1%;
}

.left {
  float: left !important;

  &.w-30 {
    width: calc(100% - 30px);
  }

  &.w50 {
    width: 48%;
  }

  &.w71 {
    width: 71%;
  }

  &.plz {
    position: relative;
    width: 32%;
  }
}

.right {
  float: right !important;

  &.w27 {
    width: 27%;
  }

  &.w50 {
    width: 48%;
  }

  &.ort {
    width: 64%;
  }

  &.all > * {
    float: right
  }
}

.nofloat {
  float: none;
  display: inline;
}

.full {
  clear: both;
  text-align: right;
  font-weight: normal;
}

.w200 {
  width: 200px;
}

.w300 {
  width: 300px;
}

.w400 {
  width: 400px;
}

.textoverflow {
  label {
    white-space: nowrap;
  }

  ~ .nolabel {
    margin-top: 17px;
  }
}

.static {
  .c17p,
  .c22p {
    width: calc(~'22.75%');
  }

  .textoverflow {
    ~ .nolabel {
      margin-top: 19px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.clear {
  clear: both;
}

.checkboxRow {
  padding: 32px 0 0;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.strike {
  text-decoration: line-through !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.textbottom {
  vertical-align: text-bottom;
}

.margin-top30 {
  margin-top: 30px !important;
}

.lh-input {
  line-height: 44px;
}

.lh-info {
  line-height: 30px;
}

.clickable {
  cursor: pointer;
  outline: none;
}

.relative {
  position: relative;
}

.nobackground {
  background-color: transparent !important;
}

.nowrap {
  white-space: nowrap;
}

.hasZahlungen {
  box-shadow: 5px 0 0 0 @yellow inset;
}

.hasZahlungenBestaetigt {
  box-shadow: 5px 0 0 0 @green inset;
}

.hasTabellennummer {
  border-left: @green 2px solid !important;
}

.hasDuplicate {
  box-shadow: 5px 0 0 0 @red inset;
}

.hasRueckfrage {
  background-color: #f5ee23;
}

.forderungsgrundWare {
  border-left: @orange 2px solid !important;
}

.autoFreigabe {
  margin-left: 5px;
  visibility: hidden;
  vertical-align: top;
}

.visible {
  visibility: visible;
}

.maxlen {
  text-align: right;
  font-size: @fs12;
}

.tableicon {
  display: inline-block;
  margin: -3px 0;

  &.fc {
    margin: 0;
    vertical-align: text-bottom;
  }
}

.circle {
  border-radius: 50%;
  border: @blue 1px solid;
  line-height: 1.4;
  padding: 0 0 3px;

  &:before {
    margin: 0 3px;
  }
}

.smallWidth {
  width: 1%;
}

.smallFont {
  font-size: .75rem;
  font-weight: normal !important;
  white-space: nowrap;
}

.smallinput {
  label {
    width: calc(~'100% - 140px') !important;
  }

  input[type=text] {
    width: 100px !important;
  }
}

.boxHeaderLink {
  float: right;
  margin: 9px 0 0;
}

.infoText {
  position: relative;
  float: right;
  width: 24px;
  height: 24px;
  border: @fontcolor 1px solid;
  border-radius: 50%;
  margin: 12px 0 0 10px;
  text-align: center;

  &.headline {
    margin-top: 2px;
  }

  &.inline {
    display: inline-block;
    float: none;
    border: none;
    margin: 0;
  }

  [data-icon]:before {
    float: none !important;
    margin: 0 !important;
  }

  > div {
    position: absolute;
    top: -5px;
    right: 35px;
    z-index: 10;
    background: rgba(10, 10, 10, .8);
    color: @white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    opacity: 0;
    transition: opacity .4s;
    padding: 7px 8px;
    border-radius: @boxBorderRadius;
    width: 300px;
    pointer-events: none;
    text-align: left;
    white-space: pre-wrap;

    &:after {
      content: '';
      position: absolute;
      right: -20px;
      top: 15px;
      width: 20px;
      height: 0;
      border: 6px solid transparent;
      border-left-color: rgba(10, 10, 10, .8);
      border-right: 0;
      margin-top: -3px;
      margin-right: -6px;
    }
  }

  &:hover > div,
  > div:hover {
    z-index: 999;
    pointer-events: auto !important;
    opacity: 1;
    transition: opacity .4s 1s;
  }
}

.infoTextFC {
  position: relative;
  float: right;
  width: 24px;
  height: 24px;
  border: @fontcolor 1px solid;
  border-radius: 50%;
  margin: 0 0 0 10px;
  text-align: center;
  font-weight: normal;

  &.hint {
    border-color: @red;
  }

  [data-icon]:before {
    float: none !important;
    margin: 0 !important;
  }

  > div {
    position: absolute;
    right: 35px;
    top: -8px;
    background: rgba(10, 10, 10, .8);
    color: @white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    opacity: 0;
    transition: opacity .4s .5s;
    padding: 5px 8px;
    border-radius: @boxBorderRadius;
    width: 400px;
    pointer-events: none;
    text-align: left;
    z-index: 999;
    line-height: 1.8;

    &.fcwait {
      height: 190px;
      color: @white;

      &:before {
        content: "";
        position: absolute;
        width: 100px;
        height: 100px;
        top: calc(~'50% - 50px');
        left: calc(~'50% - 50px');
        z-index: -1;
        background: url(/assets/loading_white.svg) center center no-repeat;
        animation: loading 6s linear infinite;
      }
    }

    > div {
      color: @white;

      div,
      span {
        color: @white;
        float: right;
        line-height: inherit !important;

        &.legend {
          float: left !important;
          margin: -1px 5px 0 0;
          line-height: 1.4;
        }

        &.indent {
          clear: both;
          float: left;
          margin: 0 0 0 17px;
        }

        span {
          margin-left: 5px;
        }

        + span {
          clear: both;
        }

        .unit {
          color: @white;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 16px;
      width: 0;
      height: 0;
      border: 6px solid transparent;
      border-left-color: rgba(10, 10, 10, .8);
      border-right: 0;
      margin-top: -3px;
      margin-right: -6px;
    }
  }

  &.show > div {
    display: block;
    opacity: 1;
    transition: opacity .4s .8s;
  }
}

.notext .infoText,
.notext .infoTextFC {
  float: none;
  margin: 0;
}

table .infoText {
  margin: 0 0 0 10px;
  font-weight: normal;

  &.inline {
    margin: -2px 0;
    vertical-align: text-top;
  }
}

.textellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  display: inline-block;
}

.c40px {
  width: 40px !important;

  span {
    display: inline-block;
    margin: -7px 0 0 0;
  }
}

.c-160px {
  width: calc(~'100% - 170px') !important;
  margin-right: 10px;
}

.inlinemargintop {
  margin-top: 15px !important;
}

.inlinemargintopCB {
  margin-top: 3px !important;
}

.centeredCB .cbwrapper {
  margin: 12px 0 0;
}

.listCB .left {
  margin-right: 50px;

  &:last-child {
    margin-right: 0;
  }
}

.marginTop {
  margin: 7px 0 0;
}

.marginTop30 .cbwrapper,
#wrapper main .box .row .marginTop30 app-checkbox-output .cbwrapper {
  margin-top: 30px !important;
}

.inactive app-text-output label,
.inactive app-text-output .textoutput,
.inactive app-numberbox-input label,
.inactive app-numberbox-input input,
.inactive app-formatted-number-input + .unit {
  opacity: .5;
}

.indented {
  padding-left: 26px;

  > label {
    display: inline-block;
    margin-bottom: 5px;
  }
}

.pre {
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-line;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -hp-pre-wrap;
  white-space: pre-wrap;

  &:before {
    content: none !important;
  }
}

hr {
  border-top: @fontcolor 1px solid;
  clear: both;

  &.double {
    border-top: @fontcolor 3px double;

    &.nomargbottom {
      margin-bottom: .1em;
    }
  }

  &.invisible {
    border-color: transparent;
  }

  &.sectiondevider {
    position: relative;
    top: -15px;
    margin: 0 0 -2px 0;
  }
}

.hrWithText {
  clear: both;
  background-color: inherit;
  border-top: @fontcolor 1px solid;
  height: 1px;
  margin: 12px 0;

  div {
    position: relative;
    background-color: inherit;
    padding: 0 5px;
    display: inline-block;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    font-size: @fs14;
  }
}

.even .hrWithText div {
  background-color: @boxgrey;
  top: -13px;
}
.odd .hrWithText div {
  background-color: @tablerow;
  top: -13px;
}

.flex {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
  align-items: stretch;
}

/*
app-error {
  display: block;
  clear: both;
}
*/

#wrapper,
.layerWrapper {
  max-width: 1440px;
  margin: 0 auto;

  h1 {
    font-size: 1.5rem;
    line-height: 1.75rem;
    font-weight: bold;
    margin: 0 0 1.737%;
  }

  h2 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: bold;
    margin: 0 0 1.429%;
  }

  a {
    color: @blue;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  header {
    position: relative;
    margin: 1.75% 0 1.737%;

    #logo {
      float: left;

      img {
        width: 84px;
        height: 84px;
      }
    }

    nav {
      float: left;
      margin: 0;
      padding: 0 0 0 40px;
      width: calc(~'100% - 84px');

      ul.top,
      ul.sub {
        list-style: none;

        li {
          position: relative;
          float: left;
          margin: 0 0 0 40px;

          a span {
            position: absolute;
            top: 22px;
            left: 100%;
            background: @blue;
            color: @white;
            font-size: @fs12;
            height: 16px;
            min-width: 16px;
            line-height: 16px;
            text-align: center;
            border-radius: 8px;
            padding: 0 4px;
          }
        }
      }

      ul.top {
        li {
          a {
            display: block;
            height: 84px;
            line-height: 84px;
            padding: 0;
            text-decoration: none;
            color: @fontcolor;
            text-transform: uppercase;
            font-size: @fs18;

            div {
              position: absolute;
              bottom: 0;
              left: 50%;
              width: 0;
              height: 2px;
              background: @grey;
              margin: auto;
              opacity: 0;
              transition: width @navHoverTiming, left @navHoverTiming, opacity @navHoverTiming;
            }

            &:hover div {
              width: 100%;
              left: 0;
              opacity: 1;
              transition: width @navHoverTiming, left @navHoverTiming, opacity @navHoverTiming;
            }

            &.selected div {
              width: 100%;
              left: 0;
              opacity: 1;
              background: @blue;
            }
          }
        }
      }

      ul.sub {
        position: relative;
        background: @boxgrey;
        margin: 10px 0 0 -124px;
        padding: 0 0 0 124px;
        min-height: 60px;

        li {
          &.backlink {
            position: absolute;
            left: 20px;
            margin: 0;
          }

          a {
            display: block;
            height: 60px;
            line-height: 60px;
            padding: 0;
            text-decoration: none;
            color: @fontcolor;
            font-size: @fs16;

            &:hover,
            &.selected {
              color: @blue;
            }

            span {
              top: 10px;
            }
          }
        }
      }
    }

    #topRight {
      position: absolute;
      top: -15px;
      right: 0;
      z-index: 2;

      a {
        display: inline-block;
        margin: 0 0 0 20px;
        cursor: pointer;
      }

      .profile,
      .languageSelect {
        position: relative;
        margin: 0;
        padding: 0;
        color: @blue;

        a {
          display: block;
          margin: 0;
          padding: 10px 15px;
          text-decoration: none !important;
        }

        ul {
          display: none;
          position: absolute;
          top: 100%;
          right: 0;
          z-index: 1000;
          list-style: none;
          border: @selectborder 1px solid;
          background-color: @white;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);

          li {
            display: block;
            color: @blue;
            cursor: pointer;

            &:hover {
              background: @boxgrey;
            }
          }
        }

        &:hover > ul,
        ul:hover {
          display: block;
        }
      }

      .profile {
        display: inline-block;
        margin: 0 0 0 20px;
      }

      .languageSelect {
        padding: 10px 15px;

        ul {
          top: 33.333333%;
          right: 95%;

          li {
            padding: 10px 15px;
          }
        }
      }
    }
  }

  main {
    margin: 0 0 2.5%;

    .navigationPager {
      float: right;
      margin: -5px 0 0 0;

      button {
        height: 45px;
        line-height: 42px;
        border: transparent 1px solid;
        width: auto;
        padding: 0 15px;

        span {
          display: inline-block;

          &:before {
            margin: 0 !important;
          }
        }

        &.prev {
          span {
            transform: rotateY(180deg);
          }

          div {
            margin: 0 0 -14px 15px;
          }
        }

        &.next {
          margin: 0 0 0 10px;

          div {
            margin: 0 15px -14px 0;
          }
        }

        div {
          display: inline-block;
          max-width: 100px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: @blue;
          /*line-height: .9;*/
        }

        &:hover {
          border-color: @blue;
        }

        &:active {
          span {
            background: transparent !important;
          }

          div {
            color: @white;
          }
        }

        &[disabled] {
          background-color: @white;
          border-color: transparent !important;

          span:before {
            color: @tablerow;
          }

          div {
            color: @tablerow;
          }
        }
      }
    }

    button {
      width: 100%;
      height: 54px;
      line-height: 52px;
      text-align: center;
      font-size: @fs16;
      color: @blue;
      background: @white;
      border: @blue 1px solid;
      border-radius: 4px;

      span[data-icon] {
        display: inline-block;
        line-height: 30px;

        &:before {
          margin: 0 10px 0 0;
        }
      }

      &.default {
        color: @white;
        background: @blue;
        text-transform: uppercase;
      }

      &.marginBottom {
        margin: 0 0 30px;
      }

      &:focus {
        border-color: @darkblue;
        outline: none;
      }

      &:active,
      &:active span[data-icon] {
        color: @white;
        background: @darkblue;

        .infoText {
          border-color: @white;

          span[data-icon] {
            &:before {
              color: @white;
            }
          }
        }
      }

      &[disabled] {
        border-color: @disabled !important;
        color: @disabled;

        &.default {
          background: @grey;
          color: @white;
        }
      }

      .infoText {
        margin: 15px 15px 0 -39px;
        line-height: 1.4;

        span[data-icon] {
          line-height: 1;

          &:before {
            vertical-align: initial;
          }
        }
      }

      &.wait {
        position: relative;
        color: @disabled20;

        &:before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: url(/assets/loading.svg) center center no-repeat;
          background-size: auto 50%;
          content: '';
          animation: loading 6s linear infinite;
        }
      }
    }

    .tabListWrapper {
      clear: both;
      border-bottom: @fontcolor 1px solid;
      margin: 0 0 30px;

      .tabWrapper {
        margin: 0;

        label {
          float: left;
          padding: 10px 20px;
          font-weight: bold;
          cursor: pointer;
          border: transparent 1px solid;
          margin: 0 -1px -1px 0;

          &:hover {
            color: @blue;
          }
        }

        input[type=radio] {
          display: none;

          &:checked + label {
            color: @blue;
            border-color: @fontcolor;
            border-bottom-color: @white;
          }
        }
      }
    }

    .box {
      position: relative;
      width: 100%;
      background: @boxgrey;
      border-radius: @boxBorderRadius;

      &.highlighted {
        background-color: @liteyellow;
      }

      &.info {
        border: @orange 2px solid;
        background: none;

        li {
          list-style: none;
        }
      }

      &.listItem {
        position: relative;
        border-radius: 0;
        border-left: transparent 3px solid;
        margin: 0 0 2px !important;

        &.unassigned:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0 10px 10px;
          border-color: transparent transparent transparent #ff4747;
          top: 0;
          left: -3px;
          position: absolute;
        }

        &:first-child {
          border-top-left-radius: @boxBorderRadius;
          border-top-right-radius: @boxBorderRadius;
        }

        &:last-child {
          border-bottom-left-radius: @boxBorderRadius;
          border-bottom-right-radius: @boxBorderRadius;
        }

        &.selected {
          background-color: @white;
          border-color: @orange;
        }

        .head {
          font-weight: bold;
        }

        .body {
          position: relative;
          font-size: @fs16;
          padding: 0 20px 0 0;

          &:after {
            position: absolute;
            top: 5px;
            right: 0;
            font-family: fontello;
            font-size: @fs16;
            content: '\e80a';
            line-height: 14px;
          }

          .beschreibung {
            font-size: @fs18;
          }

          .kommentar {
            font-size: @fs14;
            margin: 10px 0 0;
          }
        }
      }

      h2 {
        font-size: @fs24;
        line-height: @fs29;
        font-weight: bold;
        float: left;
      }

      h3 {
        clear: both;
        font-size: @fs16;
        line-height: @fs24;
        font-weight: bold;
        margin: 0 0 10px;
      }

      h4 {
        clear: both;
        font-size: @fs18;
        line-height: 1.4;
        font-weight: bold;
        margin: 30px 0 10px;
      }

      .tabListWrapper {
        margin: 0 -20px 10px;

        .tabWrapper {
          input[type=radio] {
            &:checked + label {
              border-bottom-color: @boxgrey;
            }
          }
        }
      }

      .hilightOnHover {
        margin: -7px -7px 15px;
        padding: 5px;
        border: transparent 2px dashed;
        border-radius: @boxBorderRadius;
        cursor: pointer;

        button.shadow {
          float: none;
          display: block !important;
          margin: 0;
          width: 100%;
          opacity: .2;
        }

        &:hover {
          border-color: @blue;

          button.shadow {
            opacity: 1;
          }
        }
      }

      button {
        float: right;
        width: auto;
        height: 36px;
        line-height: 34px;
        padding: 0 15px;
        font-size: @fs14;
        background: transparent;
        border: transparent 1px solid;
        margin: 0 0 0 10px;

        &.left {
          margin: 0 10px 0 0;
        }

        &.border {
          border-color: @blue;
        }

        &.default {
          background: @blue;
          text-transform: none;
        }

        &:hover {
          border-color: @blue !important;
        }

        &:focus {
          border-color: @darkblue !important;
        }

        &:active {
          border-color: @darkblue !important;
          background: @darkblue;
          outline: none;

          .infoText {
            border-color: @white;
          }
        }

        &[disabled] {
          background: @disabled !important;
          border-color: @disabled !important;
        }

        .infoText {
          margin: 5px 0 0 15px;

          span[data-icon] {
            line-height: 0;
            background-color: transparent;

            &:before {
              margin: 11px 0 0 !important;
            }
          }

          > div {
            line-height: 1.4;
          }
        }
      }

      table button {
        float: left;
        margin: 0;

        &[disabled] {
          background: none !important;
        }
      }

      .left.w50 button.noLabel,
      .right.w50 button.noLabel {
        margin: 24px 0 0;
      }

      &.buttons button {
        width: 100%;
      }

      #dragsource,
      #dragtarget {
        height: 300px;
        overflow-y: auto;
      }

      #dragdropresults {
        max-height: 250px;
        overflow-y: auto;
        margin: 0 0 20px;
        line-height: 20px;

        span {
          float: right;
          cursor: pointer;
        }
      }

      .row {
        clear: both;

        &.checkboxIndent {
          margin-left: 36px !important;
        }

        &.headlineRight {
          clear: none;
          float: right;
          margin: 8px 0 0 !important;

          &.withInput {
            margin: -6px 0 20px !important;
          }

          div {
            display: inline;

            label {
              display: inline-block;
              width: auto;
              margin: -10px 10px 0 0;
            }

            input[type=text] {
              width: 80px;
            }

            .unit {
              min-width: 0;
            }
          }
        }

        &.highlighted,
        &.warning {
          background-color: @liteyellow;
          padding: 10px;
          margin-left: -10px !important;
          margin-right: -10px !important;
          color: @sorthover;
          border-radius: 3px;
        }

        &.warning {
          background-color: @lightred;
        }

        &.downloaditem {
          > .left {
            width: calc(100% - 50px);
          }

          > .right {
            margin: -7px 0 0;
          }
        }

        &.indented {
          padding-left: 26px;
        }

        &.draggable {
          cursor: move;
        }

        &.draggable,
        &.droppable,
        &.dragdropresult {
          padding: 5px;
          background: #fff;
          border: transparent 1px solid;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &.hilight {
            border-color: @green;
          }
        }

        &.inline.alternating:nth-of-type(odd) {
          background-color: @tablerow;
        }

        label {
          display: block;
          font-size: @fs14;
          line-height: @fs17;

          .sub {
            display: block;
            font-size: @fs12;
          }

          + input[type=date],
          + input[type=datetime],
          + input[type=datetime-local],
          + input[type=email],
          + input[type=month],
          + input[type=number],
          + input[type=password],
          + input[type=range],
          + input[type=search],
          + input[type=tel],
          + input[type=text],
          + app-formatted-number-input input[type=text],
          + input[type=time],
          + input[type=url],
          + input[type=week],
          + input[type=file],
          + select,
          + p-calendar .p-calendar,
          + p-dropdown .p-dropdown,
          + .textoutput:not(.flat),
          + app-base64-upload .fileUploadWrapper,
          + p-multiselect .p-multiselect {
            margin: 2px 0 0;
          }

          + textarea {
            margin: 2px 0;
          }

          &.forExport {
            float: left;
            line-height: 36px;
            min-width: 50%;
          }
        }

        &.emptyLabel,
        .emptyLabel {
          padding-top: 19px
        }

        &.emptyLabelCheckbox,
        .emptyLabelCheckbox {
          padding-top: 31px;
        }

        input [type=date],
        input[type=datetime],
        input[type=datetime-local],
        input[type=email],
        input[type=month],
        input[type=number],
        input[type=password],
        input[type=range],
        input[type=search],
        input[type=tel],
        input[type=text],
        input[type=time],
        input[type=url],
        input[type=week],
        input[type=file],
        textarea,
        select,
        .p-dropdown,
        .p-multiselect,
        .fileUploadWrapper,
        .textoutput:not(.flat) {
          border: @white 1px solid;
          background-color: @white;
          border-radius: @inputBorderRadius;
          margin: 0;
          padding: 0 12px;
          width: 100%;
          height: @inputHeight;
          line-height: @inputHeight;
          font-size: @fs14;
          outline: none;

          &[required],
          &[required] + .fake {
            .required();
            .textellipsis();
            /*padding-right: 70px;*/
            div:before {
              right: 40px;
            }
          }

          &:hover {
            /*border: @white 1px solid !important;*/
          }

          &:focus {
            border: @blue 1px solid !important;
          }

          &.error {
            border-color: @error;
          }

          &.numeric {
            text-align: right;
            overflow: hidden;
          }

          &[disabled] {
            border-color: @tablerow !important;
            background-color: @tablerow;
          }

          &.hasInfoText {
            width: calc(~'100% - 35px');
          }

          &.hasUnit {
            width: calc(~'100% - 40px');

            &.hasInfoText {
              width: calc(~'100% - 75px');
            }
          }

          &.dragdrop {
            height: auto;
            line-height: 1.4;
            font-size: @fs16;
          }
        }

        app-base64-file + .error {
          clear: both;
        }

        textarea,
        textarea[required] {
          overflow: auto;
          resize: vertical;
          white-space: pre-wrap !important;

          &[required] {
            background-position-y: 18px;
          }
        }

        &.labelAsInput {
          input[type=text] {
            margin: 2px 0 0 0;
          }

          app-textbox-input:first-child,
          app-numberbox-input:first-child {
            float: left;
            width: calc(~'50% - 20px');
            margin-right: 20px;
          }
        }

        .p-dropdown {
          width: 100% !important;
        }

        .cbwrapper,
        .cbswrapper,
        .rbwrapper,
        .jnowrapper {
          display: inline-block;
          position: relative;

          input[type=checkbox],
          input[type=radio],
          input[type=number] {
            visibility: hidden;
            width: 22px;
            height: 22px;
            padding: 0;
          }

          input[type=checkbox],
          input[type=radio],
          input[type=number] {
            + label {
              position: absolute;
              top: 0;
              left: 0;
              width: 22px;
              height: 22px;

              &:before {
                display: block;
                content: "";
                width: 20px;
                height: 20px;
                border: @checkbox 1px solid;
                border-radius: 3px;
                background-color: @white;
              }

              span {
                position: absolute;
                top: 1px;
                left: -1px;
              }
            }

            &[required] + label:before {
              width: 18px;
              height: 18px;
              border: @blue 2px solid;
            }

            &[disabled] {
              + label:before {
                border-color: @disabled;
                background-color: @selectoritemhover;
              }

              + label:after {
                background-color: @disabled;
              }
            }

            &:checked {
              + label:before {
                border-color: @blue;
                background-color: @blue;
              }

              &[disabled] + label:before {
                border-color: @disabled;
                background-color: @disabled;
              }

              & + label:after {
                position: absolute;
                top: 3px;
                left: 7px;
                display: block;
                width: 5px;
                height: 10px;
                content: "";
                border: solid @white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }

            + label:focus:before {
              box-shadow: 0 0 0 2px @outline;
            }

            &[disabled] + label {
              cursor: default;
            }
          }

          input[type=number] {
            &[disabled] + label {
              &:before {
                background-color: transparent;
                border-color: transparent;
              }
            }
          }

          input[type=radio] {
            & + label:before {
              top: 0;
              border-radius: 50%;
              margin: -1px 0 0 0;
            }

            &:checked {
              + label:before {
                border-color: @checkbox;
                background-color: @white;
              }

              &[required]:before {
                border-color: @blue;
              }

              &[disabled] {
                + label:before {
                  border-color: @disabled;
                  background-color: @selectoritemhover;
                }

                + label:after {
                  background-color: @disabled;
                }
              }

              & + label:after {
                top: 3px;
                left: 4px;
                width: 14px;
                height: 14px;
                content: "";
                border: none;
                border-radius: 50%;
                background: @blue;
              }
            }
          }

          & + label {
            display: inline-block;
            width: calc(~'100% - 36px');
            margin: 0 0 0 14px;
            line-height: 22px;
            vertical-align: top;
          }

          &.withInfoText {
            + label {
              width: calc(~'100% - 70px');
            }

            + label + app-infotext-output .infoText {
              margin-top: -1px;
            }
          }
        }

        app-checkboxslider-input {
          > label {
            display: inline-block;
            width: auto !important;
            margin: -2px 14px 0 0;
            line-height: 22px;
            vertical-align: top;
          }

          .cbswrapper + label {
            margin: -2px 0 0 31px;
          }

          .cbswrapper {
            input[type=checkbox] {
              + label {
                width: 38px;
                height: 20px;

                &:before {
                  width: 38px;
                  height: 20px;
                  border: none;
                  border-radius: 11px;
                  border: @checkbox 1px solid;
                }

                &:after {
                  display: block;
                  position: absolute;
                  top: 4px;
                  left: 4px;
                  content: "";
                  width: 14px;
                  height: 14px;
                  border-radius: 50%;
                  border: none;
                  background-color: @checkbox;
                  -webkit-transform: initial;
                  -ms-transform: initial;
                  transform: initial;
                  transition: left .15s;
                }
              }

              &[required] + label:before {
                width: 14px;
                height: 14px;
                border: @blue 2px solid;
              }

              &[disabled] + label:before {
                background-color: @selectoritemhover;
              }

              &:checked {
                + label:before {
                  border-color: @checkbox;
                  background-color: @white;
                }

                &[disabled] {
                  + label:before {
                    background-color: @selectoritemhover;
                  }

                  + label:after {
                    background-color: @disabled;
                  }
                }

                & + label:after {
                  top: 4px;
                  left: 22px;
                  border: none;
                  width: 14px;
                  height: 14px;
                  background-color: @blue;
                  -webkit-transform: initial;
                  -ms-transform: initial;
                  transform: initial;
                  transition: left .15s;
                }
              }

              &[disabled] {
                + label:before {
                  background-color: @selectoritemhover;
                }

                + label:after {
                  background-color: @checkbox;
                }
              }

              + label:focus:before {
                box-shadow: 0 0 0 2px @outline;
              }

              &[disabled] + label {
                cursor: default;
              }
            }
          }
        }

        .jnowrapper {
          label span {
            line-height: 1;

            &.grey {
              font-size: @fs16;

              &:before {
                margin: -2px 4px 0;
              }
            }

            &.green {
              font-size: @fs24;

              &:before {
                margin: -10px 0 0;
              }
            }

            &.red {
              font-size: @fs24;

              &:before {
                margin: -10px 0 0;
              }
            }

            &.blue {
              font-size: @fs15;

              &:before {
                margin: 1px 0 0 5px;
              }
            }
          }
        }

        .radiobuttonListWrapper {
          margin: 0;

          .radiobuttonWrapper {
            margin: 0 0 15px;

            &:last-child {
              margin: 0;
            }
          }
        }

        &.inLine .radiobuttonWrapper {
          float: left;
          margin: 0 45px 0 0;
        }

        input[type=file] {
        }

        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        input[type=number] {
          -moz-appearance: textfield;
        }

        textarea {
          height: 150px;
          min-height: 150px;
          resize: vertical;
          line-height: 1.4;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        .vwz textarea {
          height: 80px;
          min-height: 80px;
        }

        select {
          padding: 0;
        }

        p-dropdown.required label,
        [ng-reflect-required] .p-fileupload-choose {
          .required();
          text-overflow: ellipsis;
        }

        p-dropdown.required {
          background: none;
          padding-right: 0;

          .p-dropdown {
            background-image: url(/assets/required.png) !important;
            background-position: right 30px top 50% !important;
            background-repeat: no-repeat !important;
          }
        }

        .error > div {
          color: @error;
        }

        app-checkbox-input,
        app-radiobutton-input {
          .error {
            padding-left: 33px;
          }
        }

        app-checkbox-output {
          /*.cbwrapper,*/
          .cbswrapper /*,
          > label*/ {
            margin: -2px 0 0 14px !important;
          }
        }

        &.checkboxWithLink {
          app-checkbox-input {
            float: left;
            margin: 0 10px 0 0;
          }

          app-checkbox-input + app-download {
            float: left;
            margin: 2px 0 0 0;
            max-width: calc(100% - 40px);
          }
        }

        input[type=radio],
        input[type=checkbox] {
          + label + .error {
            margin: 0 0 0 33px;
          }
        }

        .textoutput {
          &.numeric {
            white-space: nowrap;
            text-align: right;
          }

          .infoText {
            float: right;
            margin: -3px 0 0 10px
          }
        }

        &.summe {
          margin-bottom: 30px !important;
          font-weight: bold;

          &:last-child {
            margin-bottom: 15px !important;
          }

          .textoutput.numeric {
            margin: 5px 0 10px;
            min-height: 0 !important;

            &.flat {
              padding: 0 13px !important;
            }
          }
        }

        &.afterSummeNoSpace {
          margin-top: -30px !important;
        }

        .textoutput:not(.flat),
        .textoutput:not(.flat):hover {
          border-color: @tablerow !important;
          background-color: @tablerow;
          line-height: 43px;
          line-height: 1.4;
          padding: 12px 12px;
          height: auto;
          min-height: 45px;
        }

        .download {
          .textellipsis()
        }

        &.withButtonAfter {
          input[type=text],
          select,
          .p-dropdown,
          .textoutput:not(.flat),
          .fileUploadWrapper,
          .download {
            float: left;
            width: calc(~'100% - 45px') !important;
            margin-right: 10px;
          }

          input[type=text].hasUnit {
            width: calc(~'100% - 85px') !important;
          }

          &.inline input[type=text].hasUnit {
            width: calc(~'50% - 85px') !important;
          }

          &.twoButtons {
            .fileUploadWrapper,
            .download {
              width: calc(~'100% - 72px') !important;
            }
          }

          &.threeButtons {
            .fileUploadWrapper,
            .download {
              width: calc(~'100% - 110px') !important;
            }
          }

          &.fourButtons {
            .fileUploadWrapper,
            .download {
              width: calc(~'100% - 138px') !important;
            }
          }

          .actionbutton {
            float: left;
            margin: 0 5px 0 0;

            &.disabled {
              cursor: default;
              color: @disabled !important;

              span {
                color: @disabled !important;
              }
            }

            &:last-child {
              margin: 0;
            }

            [data-icon]:before {
              margin: 5px 5px 0;
            }

            &.afterInput {
              [data-icon]:before {
                margin: 11px 5px 0;
              }
            }
          }

          &.noinput {
            .actionbutton {
              [data-icon]:before {
                margin: 3px 5px 0;
              }
            }
          }

          .download {
            line-height: 30px;
          }

          [data-icon]:before {
            float: right;
            margin: 10px 0 0 0; /*...10px*/
          }
        }

        .unit {
          display: inline-block;
          min-width: 30px;
          margin: 0 0 0 10px;
        }

        .flat .unit {
          min-width: 0;
          margin: 0 0 0 3px;
        }

        &.disabledCol .flat .unit {
          margin: 0 13px;
        }

        &.inputCol .flat .unit {
          margin: 0 3px 0 22px;
        }

        td {
          .unit {
            min-width: 0;
          }

          &.withInput {
            .unit {
              margin-left: 23px;
            }
          }

          .download {
            width: auto;
            vertical-align: bottom;
          }
        }

        p-calendar {
          .p-calendar.p-calendar-w-btn .p-inputtext,
          .date {
            width: 105px !important;
          }

          &[required] {
            .p-calendar.p-calendar-w-btn .p-inputtext {
              .required();
              width: 135px !important;
            }
          }

          .likeRequired.p-calendar.p-calendar-w-btn .p-inputtext {
            width: 135px !important;
          }
        }

        &.fullwidth > button {
          width: 100%;
          margin: 0 0 20px;
          border-color: @blue;

          &:last-child {
            margin: 0;
          }
        }

        &.inline {
          label {
            float: left;
            width: 50%;
          }

          &.shortLabel {
            label {
              width: 200px;
            }

            .numeric {
              text-align: left;
            }
          }

          .autoLabelBefore {
            label {
              width: auto;
              margin: 0 10px 0 0;
            }
          }

          app-checkbox-input {
            display: block;
            margin: -2px 0 2px 0;
          }

          &.withInput {
            /*background-color: transparent !important;*/
            app-textbox-input,
            app-numberbox-input {
              label {
                line-height: 31px;
                padding: 9px 0 0;
              }

              .unit {
                line-height: 49px;
              }
            }

            app-text-output {
              .unit {
                margin-left: 23px;
              }
            }
          }

          &:not(.labelAsInput) input[type=text],
          &:not(.labelAsInput) textarea,
          &.labelAsInput app-textbox-input:not(:first-child) input[type=text] {
            float: left;
            width: 50%;

            &.hasUnit {
              width: calc(~'50% - 40px');
            }
          }

          .textoutput:not(.flat) {
            float: left;
            width: 50%;
            height: auto;
            line-height: normal;
            background-color: transparent;
            border: none !important;
            border-radius: 0;
            margin: 0;
            padding: 0;
          }

          .textoutput.numeric {
            white-space: nowrap;
            line-height: 1.0625rem;
          }

          &.halfwidth {
            .textoutput:not(.flat) {
              width: 25%;
            }
          }

          &.withButtonAfter {
            .textoutput:not(.flat) {
              width: calc(~'50% - 37px') !important;
            }
          }

          &.alternating {
            &:not(:last-child) {
              margin-bottom: 0 !important;
            }

            label {
              line-height: 1.4;
              padding: 7px 0;
            }

            .textoutput:not(.flat) {
              height: 34px;
              min-height: 34px;
              line-height: 34px;
              overflow: hidden;
            }

            &.withInput:not(.inline) {
              .textoutput:not(.flat) {
                float: right;
                width: auto;
                height: 49px;
                line-height: 49px;
              }
            }
          }

          .w27 .textoutput:not(.flat) {
            line-height: 49px;
            float: none;
            width: auto;
          }

          &:not(.withInput) [data-icon]:before {
            margin-top: -4px;
          }

          .radiobuttonListWrapper {
            float: left;
            width: 50%;

            .radiobuttonWrapper {
              margin: 0;

              .rbwrapper input[type=radio]:checked + label:after {
                top: 10px;
              }

              label {
                display: inline-block;
                float: none;
                width: calc(100% - 34px);
              }
            }
          }
        }

        &.topbutton > button {
          border-color: @blue;
        }

        &.button {
          a {
            line-height: 32px;
          }
        }

        &.email {
          &.pre,
          & > * {
            word-wrap: break-word;
            white-space: pre;
            white-space: pre-wrap;
            white-space: pre-line;
          }

          ul,
          ol {
            margin: 10px 0;
            white-space: initial;

            li {
              margin: 0 0 0 18px;
            }
          }

          table {
            width: 100%;
            margin: 10px 0;
            white-space: initial;
            border-spacing: 0;
            border-collapse: collapse;

            th,
            td {
              text-align: left;
              vertical-align: top;
              padding: 2px 5px;
              border: @tableborder 1px solid;
            }
          }
        }

        &.mailable {
          label {
            display: inline-block;
            width: calc(~'100% - 40px')
          }

          app-checkbox-input label {
            width: calc(~'100% - 70px');
          }

          .mail {
            float: right;
            margin: -7px 0;

            [data-icon]:before {
              margin: 0;
            }
          }
        }

        .attachments {
          margin: 0 0 5px;

          &:last-child {
            margin: 0
          }

          a {
            display: block;
            .textellipsis()
          }

          &.deletable {
            a,
            .fileUploadWrapper {
              float: left;
              width: calc(~'100% - 50px');

              &.delete {
                float: right;
                width: auto;
              }
            }

            app-upload-input + .delete {
              margin: 7px 0 0;
            }
          }
        }

        .listLayer {
          position: absolute;
          left: 0;
          width: 200%;
          list-style: none;
          padding: 0;
          border: @selectborder 1px solid;
          background-color: @white;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          max-height: 200px;
          overflow-y: auto;
          border-radius: @boxBorderRadius;
          opacity: 0;
          top: 120%;
          transition: opacity .25s, top .25s;
          display: none;

          &.showLayer {
            display: block;
            opacity: 1;
            top: 100%;
            transition: opacity .25s, top .25s;
          }

          li {
            margin: 0;
            padding: 0.429em 0.857em;

            &:hover {
              background-color: @selectoritemhover;
            }
          }
        }

        .emailHeaderLabel {
          float: left;
          width: 45px;
        }

        .emailHeaderValue {
          float: left;
          width: calc(~ '100% -  45px');
        }

        &.multiControlInline {
          .cbwrapper + label,
          .cbswrapper + label,
          .textoutput {
            width: auto;
            margin-right: 3px;
          }

          .textoutput:not(.flat),
          .textoutput:not(.flat):hover {
            display: inline-block;
            border: none;
            background: transparent;
            width: auto;
            min-height: unset;
            margin: 0 0 2px 0;
            padding: 0;
            text-align: left;

            .unit {
              min-width: unset;
              margin: 0 0 0 3px;
            }
          }
        }

        &.export {
          .left {
            margin: -7px 14px 0 0;

            label {
              display: inline;
              margin-right: 10px;
            }
          }
        }
      }

      app-h2-output + .row.right {
        clear: none;
        margin-top: 3px !important;
      }

      .scrollable {
        max-height: 87px;
        overflow-y: auto;

        .scrollableItem {
          &:hover {
            background-color: @tablerow;
          }

          div {
            padding: 3px;
          }
        }
      }

      p-table {
        /*position: relative;*/
        p-sorticon {
          display: none;
        }

        .fixHead {
          thead {
            position: sticky;
            top: -1px;

            &.fixed {
              background: #fff;
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, .4);
              transition: all .3s;
              z-index: 10;

              th {
                border-bottom: none;
                box-shadow: none !important;
                pointer-events: none !important;
                cursor: pointer !important;
              }
            }
          }
        }

        .textoutput.numeric {
          white-space: nowrap;
          text-align: right;
        }
      }

      .mixedRows {
        .row > app-textbox-input:last-child .unit,
        .row > app-numberbox-input:last-child .unit {
          text-align: right;
        }

        app-text-output {
          .unit {
            margin-left: 23px;
          }
        }
      }

      &.topfilter {
        .hrWithText {
          margin-bottom: 19px;
        }
      }
    }

    .kachelcontainer {
      .flex();
      flex-wrap: wrap;

      .kachel {
        background: @boxgrey;
        border-radius: @boxBorderRadius;
        padding: 10px;
        cursor: pointer;

        &.cols3 {
          width: 32%;
          margin: 0 0 2% 2%;

          &:first-child,
          &:nth-child(3n+1) {
            margin-left: 0;
          }
        }

        &.selected {
          box-shadow: 0 0 5px 0 @blue;
        }

        img {
          width: 100%;
          height: auto;
          margin: 0 0 10px;
        }
      }
    }

    .emailList {
      max-height: 580px;
      overflow-y: auto;
    }

    .todoList,
    .emailList {
      max-height: 738px;
      overflow-y: auto;

      &.shortList {
        max-height: 550px;
      }
    }

    .todoinfo {
      float: left;
    }

    .col.span_12_of_12 .box {
      margin: 0 0 2.084%;
      padding: 1.112% 1.389% .348%;

      &.topfilter {
        padding: 0;
        margin: 0 0 2.143%;

        .row {
          clear: none;
          margin: 0;

          .left {
            white-space: nowrap;
            margin: 0 15px 0 0;

            .clearfilter {
              margin: 0;
            }
          }

          .noLabel {
            padding-top: 17px;
            line-height: 49px;

            .cbwrapper + label,
            .cbswrapper + label {
              margin-top: 14px;
            }

            button {
              margin-top: 7px;
            }

            .cbwrapper,
            .cbswrapper,
            .rbwrapper {
              margin: 14px 0 -14px 0;
            }
          }
        }
      }

      &.marginbotton {
        margin: 0 0 2.084%;
      }

      .row {
        margin: 0 0 1.072%;

        &.inline {
          margin-left: -1.429%;
          margin-right: -1.429%;
          padding: 0 1.429%;
        }

        &.hoverhead {
          padding: 0 .35733333%;
        }

        &.hover {
          margin: -5px 0 10px;
          padding: .35733333%;

          &:hover {
            background: @tablerow;
          }
        }

        &.dragdropresult {
          margin: 0 0 .5%;
        }
      }

      .col.span_4_of_12 .row {
        margin: 0 0 3.575%;
      }
    }

    .col.span_9_of_12 {
      .box {
        margin: 0 0 2.798%;
        padding: 1.493% 1.866% .467%;

        h2 {
          margin: 0 0 1.938%;
        }

        .row {
          margin: 0 0 1.454%;

          &.verticalSroll {
            margin-left: -20px;
            margin-right: -20px;
          }

          &.halfspace {
            margin: 0 0 .727%;
          }

          &.inline {
            margin-left: -1.938%;
            margin-right: -1.938%;
            padding: 0 1.938%;
          }
        }
      }

      .span_5_of_12 {
        .box {
          margin: 0 0 6.918%;
          padding: 3.686% 4.607% 1.153%;

          h2 {
            margin: 0 0 5.074%;
          }

          .row {
            margin: 0 0 3.808%;

            .dragdroparea div {
              width: 90%;
            }
          }
        }
      }

      .span_6_of_12 {
        .box {
          margin: 0 0 5.716%;
          padding: 3.073% 3.84% .962%;

          h2 {
            margin: 0 0 4.157%;
          }

          .row {
            margin: 0 0 3.12%;
          }
        }
      }

      .span_7_of_12 {
        .box {
          margin: 0 0 4.87%;
          padding: 2.634% 3.29% .823%;

          h2 {
            margin: 0 0 3.523%;
          }

          .row {
            margin: 0 0 2.643%;
          }
        }
      }
    }

    .col.span_8_of_12 .box {
      margin: 0 0 3.159%;
      padding: 1.685% 2.1068% .527%;

      h2 {
        margin: 0 0 2.3%;
      }

      .row {
        margin: 0 0 1.65%;

        &.halfspace {
          margin: 0 0 0.825%;
        }

        &.inline {
          margin-left: -2.198%;
          margin-right: -2.198%;
          padding: 0 2.198%;
        }
      }
    }

    .col.span_6_of_12 {
      &.twoColumnFilter {
        .row {
          padding: 0 2.838%;

          &.leftCol {
            padding-left: 0;
          }

          &.rightCol {
            padding-right: 0;
          }
        }
      }

      .box {
        margin: 0 0 4.257%;
        padding: 2.27% 2.838% .71%;

        h2 {
          margin: 0 0 3.01%;
        }

        .row {
          margin: 0 0 2.258%;

          &.halfspace {
            margin: 0 0 1.42%;
          }

          &.draggable,
          &.droppable {
            margin: 0 0 1%;
          }

          &.inline {
            margin-left: -3.01%;
            margin-right: -3.01%;
            padding: 0 3.01%;

            &.largeLabel {
              label {
                width: 64%;
              }

              .textoutput:not(.flat) {
                width: 33%;
              }
            }
          }
        }
      }
    }

    .col.span_5_of_12 .box {
      margin: 0 0 5.153%;
      padding: 2.748% 3.435% .86%;

      h2 {
        margin: 0 0 3.687%;
      }

      .row {
        margin: 0 0 2.767%;

        &.halfspace {
          margin: 0 0 1.715%;
        }
      }
    }

    .col.span_4_of_12 .box {
      margin: 0 0 6.525%;
      padding: 3.48% 4.35% 1.09%;

      &.info {
        padding: 3% 4.35% .3%;

        .row {
          color: @orange;
        }
      }

      h2 {
        margin: 0 0 4.765%;
      }

      .row {
        margin: 0 0 3.575%;

        &.halfspace {
          margin: 0 0 1.8%;
        }

        &.inline {
          margin-left: -4.6%;
          margin-right: -4.6%;
          padding: 0 4.6%;
        }
      }
    }

    .col.span_3_of_12 .box {
      margin: 0 0 8.895%;
      padding: 4.745% 5.93% 1.485%;

      h2 {
        margin: 0 0 6.73%;
      }

      .row {
        margin: 0 0 5.045%;

        &.halfspace {
          margin: 0 0 2.99%;
        }
      }
    }

    .span_8_of_12 {
      .span_6_of_12 {
        .box {
          margin: 0 0 6.452%;
          padding: 3.444% 4.302% 1.076%;

          .row {
            margin: 0 0 3.421%;

            &.inline {
              margin-left: -4.7%;
              margin-right: -4.7%;
              padding: 0 4.7%;
            }
          }
        }
      }
    }

    .span_9_of_12 .box {
      .span_3_of_12 {
        .row {
          margin: 0 0 6.206%;
        }
      }
    }

    .span_12_of_12 .box {
      .span_3_of_12 {
        .row {
          margin: 0 0 4.574%;
        }
      }
    }
    /*Layer*/
    .p-dialog {
      .col.span_12_of_12 .box {
        margin: 0;
        padding: .851% 1.702% .426%;

        .row {
          margin: 0 0 1.321%;
        }
      }
    }
  }


  .klapper {
    position: relative;
    padding: .737% 1.389%;
    background: @tablerow;
    cursor: pointer;

    span {
      position: absolute;
      right: 1.389%;
      transform: rotate(90deg);
      transition: transform .25s;
    }

    &.open span {
      transform: rotate(-90deg);
      transition: transform .25s;
    }
  }

  .klapperHead {
    clear: both;
    padding: 0 10px;
    font-size: 1rem;
    line-height: 2.5rem;
    font-weight: bold;
    margin: 0 -10px 15px;
  }

  .klapperBody {
    overflow: hidden;
    max-height: 0;
    transition: max-height .25s;

    &.open {
      max-height: 200px;
      transition: max-height .25s;
    }
  }

  .klapperlink {
    position: relative;
    display: block;
    width: 18px;
    height: 18px;

    span {
      position: absolute;
      top: -3px;
      transform: rotate(90deg);
      transition: transform .25s;
    }

    &.open span {
      transform: rotate(-90deg);
      transition: transform .25s;
    }
  }

  .qaContainer {
    overflow: hidden;
    max-height: 0;

    &.open {
      max-height: none;
      margin-bottom: 2.084%;
    }
  }

  footer {
    text-align: center;
    font-size: @fs12;
    padding: 10px 0;
  }

  .clearfilter {
    float: right;
    margin: 1px 0 0;

    &.multi {
      margin-left: 10px;
    }
  }

  .inHeadline {
    float: right;
    margin: -35px 0 0;
  }

  .hidden {
    display: none !important;
  }

  main .col.span_4_of_12 .box.usermessage .row {
    margin: 0 0 2.4%;
  }
  /*
   *
   *
   *
   *
   *
   * prime-ng default style �berschreiben*/
  .p-widget {
    font-family: Lato, Verdana, Arial, Helvetica, sans-serif;
    color: @fontcolor;
  }

  .p-datatable-wrapper {
    margin: 0 -20px 15px;
    min-height: 200px;
  }

  .p-datatable {
    position: initial;

    .p-datatable-loading-overlay {
      background-color: rgba(255, 255, 255, 0.5) !important;
      opacity: 1;
      pointer-events: unset;

      .pi-spin {
        animation: none !important;
      }

      .pi-spinner:before {
        content: "";
      }
    }

    table {
      border-collapse: collapse;
      table-layout: auto;
      width: 100%;

      .label {
        width: 200px;
      }

      .p-datatable-thead > tr {
        background: none;

        app-abgeschlossen-hider {
          display: contents;
        }

        th {
          padding: 8px 10px;
          border: none;
          border-bottom: @fontcolor 1px solid;
          font-weight: bold;
          color: @fontcolor;
          background-color: transparent;
          text-align: left;
          vertical-align: top;

          .sortIcon {
            display: none;
            font-family: 'primeicons';
            font-size: 1.25em;
            line-height: 1.2;
            vertical-align: 0;
            color: @fontcolor !important;
            width: 1.28571429em;
            text-align: center;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            -webkit-font-smoothing: antialiased;
            pointer-events: none;
            vertical-align: -3px;
          }

          &:hover .sortIcon {
            color: @sorthover !important;
          }

          &.asc .sortIcon {
            display: inline-block;

            &:before {
              content: '\e914';
            }
          }

          &.desc .sortIcon {
            display: inline-block;

            &:before {
              content: '\e913';
            }
          }
        }

        &.filtered th {
          border: none;

          &.filtered {
            border-bottom: #fb0 2px solid;
          }
        }
      }

      .p-datatable-tbody > tr {
        background: none;

        app-abgeschlossen-hider {
          display: contents;
        }

        &:not(.disableAutoEvenOdd):nth-child(2n) {
          background-color: @tablerow;
        }

        &.even {
          background: none;
        }

        &.odd {
          background-color: @tablerow;
        }

        > td,
        > app-abgeschlossen-hider > td {
          padding: 8px 10px;
          border: none;

          &.paddingTop {
            padding-top: 40px;
          }

          &.error {
            background-color: @cellError;
          }

          app-checkbox-input,
          app-checkbox-output {
            display: inline-block;
            margin: 0 0 0 -10px;
          }

          input[type=text].short {
            width: 80px !important;
          }

          h3 {
            margin: 0;
          }

          a app-text-output * {
            color: @blue;
          }

          &.separated {
            border-left: @grey 1px solid !important;
          }

          .radiobuttonListWrapper {
            margin: 7px 0 0;
          }

          .fs12:before {
            margin-top: -5px;
          }

          .cbwrapper,
          .cbswrapper,
          .rbwrapper,
          .jnowrapper {
            display: inline-block;
            position: relative;
            top: 2px;
          }
        }
      }

      .p-datatable-tfoot > tr {
        background: none;

        app-abgeschlossen-hider {
          display: contents;
        }

        > td {
          padding: 8px 10px;
          border: none;
          color: @fontcolor;
          background-color: transparent;
        }

        &:first-child td {
          border-top: @fontcolor 1px solid;
        }
      }

      tr th:first-child,
      tr td:first-child {
        padding-left: 20px
      }

      tr th:last-child,
      tr td:last-child {
        padding-right: 20px;
      }

      tr td.tableContainerCell {
        padding: 0;

        & > div {
          overflow-y: auto;
          max-height: 0;
          transition: max-height .4s;

          &.show {
            max-height: 500px;
            transition: max-height .4s;
          }
        }

        td.tdFilelist,
        td.tdDragdrop {
          width: 50%;
          vertical-align: top;

          .actionbutton {
            margin: -2px 0 0;
          }
        }
      }

      &.validationErrors {
        th, td {
          white-space: nowrap;
          max-width: 250px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .verticalSroll .p-datatable {
    overflow-x: auto;

    .p-datatable-wrapper {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .p-paginator {
    background-color: transparent;
    border: none;
    margin: 0 0 15px;

    button {
      float: left !important;
    }

    .p-paginator-pages .p-paginator-page.p-hightlight {
      background-color: @blue;
      color: @white;
      border-radius: 4px;
      text-decoration: none;
    }
  }

  .p-button-icon-only .p-button-icon {
    color: @blue;
    font-size: @fs26;
  }

  .p-datepicker-trigger {
    &.p-button-icon-only .p-button-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -.7em;
      margin-left: -.3em;
    }

    .p-button-label {
      width: 35px;
    }
  }

  main .box .row .p-datepicker-header,
  .p-datepicker-header {
    padding-left: 0;
    padding-right: 0;

    a {
      top: 5px;
    }

    select {
      width: auto;
      height: auto;
      line-height: normal;
    }

    .p-link {
      margin: 0;
    }
  }

  .p-calendar.p-calendar-w-btn {
    .p-datepicker-trigger.p-button {
      width: auto;
      height: @inputHeight;
      margin: 0;
      padding: 0;
      border: none;
      background: transparent;
      color: @white;
      box-shadow: none;
      border-radius: 0;

      &[disabled] {
        display: none;
      }
    }
  }

  .pi-calendar:before {
    font-family: fontello;
    font-size: @fs20;
    content: '\e808';
    line-height: 14px;
  }

  .p-datepicker {
    table th,
    table td {
      padding: .25rem;

      &.p-datepicker-weekheader,
      &.p-datepicker-weeknumber {
        border-right: 1px solid #dee2e6;
      }

      & > a,
      & > span {
        color: @fontcolor;
        text-decoration: none;

        &.p-active {
          color: @white;
        }
      }
    }

    .p-timepicker {
      width: 250px;
    }

    .p-datepicker-buttonbar {
      padding: 5px 0 !important;

      .p-g {
        display: block !important;

        .p-g-6 {
          float: right !important;
          width: auto !important;
        }
      }

      .p-button {
        background: #fff;
        border: @blue 1px solid;
        height: 36px !important;
        line-height: 34px !important;
        margin: 0;
        padding: 0 15px !important;

        .p-button-text,
        .p-button-label {
          color: @blue !important;
          padding: 0 !important;
          line-height: 0 !important;
        }

        &:active {
          background: @darkblue !important;

          .p-button-text,
          .p-button-label {
            color: @white !important;
          }
        }

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .p-monthpicker {
    width: 250px;

    .p-state-active {
      color: @white;
    }
  }

  .p-inputtext:enabled:hover:not(.p-error) {
    border-color: transparent;
  }

  .p-dropdown {
    padding-right: 2rem;
    transition: none;

    &:not(.p-disabled).p-focus {
      border-color: @blue !important;
    }

    .p-dropdown-label {
      padding: 0;
      height: @inputHeightInnerLabel;
      line-height: @inputHeightInnerLabel !important;
      color: @fontcolor;
    }

    .p-dropdown-trigger {
      border-top-right-radius: @inputBorderRadius;
      border-bottom-right-radius: @inputBorderRadius;
      margin-right: -15px;
    }

    .p-dropdown-panel {
      padding: 0;
      border: 1px solid #c8c8c8;
      background-color: #fff;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
    }

    .p-dropdown-header {
      padding: 0;
    }

    .p-dropdown-filter-container {
      padding: .429em .857em;
      border-bottom: 1px solid #eaeaea;
      color: #333;
      background-color: #fff;
      margin: 0;

      .p-dropdown-filter-icon {
        top: 50%;
        margin-top: -.5em;
        right: 1.357em;
        color: #007ad9;
      }
    }

    .p-dropdown-items-wrapper {
      .p-dropdown-item {
        margin: 0;
        padding: .429em .857em;
        border: 0;
        color: #333;
        background-color: transparent;
        border-radius: 0;
        line-height: normal;

        &:not(.p-highlight):not(.p-disabled):hover {
          background-color: #eaeaea;
        }

        &.p-highlight,
        .p-dropdown-item-group.p-highlight {
          background-color: #007ad9;

          span {
            color: @white;
          }
        }
      }
    }
  }

  .p-multiselect-label {
    padding: 0 !important;
    line-height: 43px;
  }

  .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0 1rem;
  }

  .p-multiselect-close {
    display: none;
  }

  .fileUploadWrapper {
    position: relative;
    padding: 0 !important;
    margin-top: 2px !important;

    input[type=file] {
      opacity: 0;
      border: 0 !important;
    }

    .fake {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 50px 0 55px;
      line-height: 43px;
      pointer-events: none;
      .textellipsis();

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        height: 43px;
        width: 43px;
        line-height: 43px;
        color: @blue;
        border-radius: @inputBorderRadius;
        content: '\e806';
        font-family: fontello;
        font-size: @fs43;
        text-align: center;
      }

      > div:before {
        position: absolute;
        top: 10px;
        right: 20px;
        width: 23px;
        height: 23px;
        line-height: 23px;
        color: @blue;
        content: "\e90b";
        font-family: primeicons;
        font-size: @fs24;
        text-align: center;
        pointer-events: all;
        cursor: pointer;
      }
    }

    &.dragdrop {
      input[type=file] {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        height: auto !important;
      }

      .dragdroparea {
        background: @white;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: @white 1px solid;
        border-radius: @inputBorderRadius;
        pointer-events: none;
        height: 150px;

        div {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 80%;
          transform: translate(-50%,-50%);
          pointer-events: none;
          text-align: center;

          span {
            display: block;
            line-height: 1;
            margin: 0 auto 10px;
          }
        }
      }
    }

    &.disabled {
      background-color: @tablerow !important;
      border-color: @tablerow !important;

      .fake:before {
        color: @disabled;
      }
    }
  }

  .p-toast {
    width: 40%;
    margin-top: -3px;
  }

  .p-toast-top-center {
    top: 0;
    left: 50%;
  }

  .p-toast .p-toast-message,
  .usermessage {
    background-color: @white;
    border-width: 0;
    border-bottom: @white 3px solid;
    border-radius: 3px;
    padding: 20px;

    &.p-toast-message-success,
    &.success {
      background-color: lighten(@green, 40%);
      border-color: @green;
    }

    &.p-toast-message-error,
    &.error {
      background-color: lighten(@red, 30%);
      border-color: @red;
    }

    &.p-toast-message-warn,
    &.warn {
      background-color: lighten(@orange, 40%);
      border-color: @orange;
    }

    &.p-toast-message-info,
    &.info {
      background-color: lighten(@blue, 40%);
      border-color: @blue;
    }

    .icon {
      float: left;
      width: 70px;

      span:before {
        margin-top: -5px;
      }
    }

    .text {
      float: left;
      width: calc(~'100% - 70px');
      padding: 25px 0;
      font-size: @fs16;
    }
  }
}

body,
#wrapper main {
  .p-dialog {
    max-width: 1440px;
    padding: 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);

    .p-dialog-header {
      background-color: @boxgrey !important;
      border: none !important;
      padding: 20px !important;

      a {
        text-decoration: none !important;
      }

      .p-dialog-title {
        font-size: @fs24;
        font-weight: bold;
      }

      .p-dialog-header-icon {
        margin: .1rem 0 0 .5rem;
        float: right;
        width: auto;
        height: auto;
        line-height: 1.4;
        text-align: center;
        background: none;
        border: none;
        border-radius: 50%;

        .pi {
          font-size: @fs32;
          color: @blue;
        }

        .pi-fw {
          width: auto;
        }
      }
    }

    .p-dialog-content {
      padding: 20px 20px 0 !important;
      border: none !important;
      max-height: 850px;
      overflow-y: scroll;

      main {
        margin: 0 !important;
      }

      .box {
        margin: 0;

        textarea {
          resize: none !important;
        }
      }
    }

    &.validation .p-dialog-content {
      overflow-x: auto;

      .layerWrapper {
        max-width: initial;

        .span_12_of_12,
        .box {
          width: auto;
          min-width: 100%;

          .p-datatable-wrapper {
            margin: 0 -1.389% 15px;
          }
        }
      }
    }

    .p-dialog-footer {
      border: none !important;
      padding: 20px 20px 15px;

      .box {
        background: none !important;
      }
    }

    &.p-confirm-dialog {
      width: 38em;

      .p-dialog-content {
        overflow: auto;

        .p-confirm-dialog-message {
          margin: 0;
        }
      }
    }
  }

  p-dynamicdialog .p-dialog-mask {
    background-color: rgba(0, 0, 0, .4);
  }

  .p-table {
    .p-sortable-column {
      .p-sortable-column-icon {
        color: @selectborder !important;

        &.pi-sort {
          display: none;
        }

        &.pi-sort-up {
          vertical-align: -3px;
          color: @fontcolor !important;
        }

        &.pi-sort-down {
          vertical-align: 0;
          color: @fontcolor !important;
        }
      }

      &:hover .p-sortable-column-icon {
        color: @sorthover !important;
      }
    }
  }
  /*.p-disabled,*/
  .p-component:disabled {
    opacity: 1 !important;
  }

  .p-disabled {
    .p-inputtext {
      background: @tablerow !important;
    }

    &.p-dropdown {
      background: @tablerow !important;
      border-color: @tablerow !important;
      opacity: 1;

      .p-dropdown-trigger {
        display: none;
      }
    }
  }
}

#loading.loading-hidden {
  display: none !important;
}
