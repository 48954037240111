/*  SECTIONS  */
.section {
  clear: both;
}


/*  COLUMN SETUP  */
.col {
  display: block;
  float: left;
  margin: 0 0 0 2.0835%;
}



.span_8_of_12 .col {
  margin: 0 0 0 3.159%;
}

.span_9_of_12 .span_3_of_12 {
  margin: 0 0 0 2.906%;
}

.span_9_of_12 .span_5_of_12 {
  margin: 0 0 0 2.798%;
}

.span_9_of_12 .span_6_of_12 {
  margin: 0 0 0 2.798%;
}

.span_9_of_12 .span_7_of_12 {
  margin: 0 0 0 2.798%;
}


.col:first-child {
  margin-left: 0;
}

/*  GROUPING  */
.group:before,
.group:after {
  content: "";
  display: table;
}

.group:after {
  clear: both;
}

/*  GRID OF TWELVE  */
.span_12_of_12 {
	width: 100%;
}

.span_11_of_12 {
  	width: 91.49%;
}
.span_10_of_12 {
  	width: 82.98%;
}

.span_9_of_12 {
  	width: 74.47%;
}

.span_8_of_12 {
  	width: 65.97%;
}

.span_7_of_12 {
  	width: 57.46%;
}

.span_6_of_12 {
  	width: 48.95%;
}

.span_5_of_12 {
  	width: 40.45%;
}

.span_4_of_12 {
  	width: 31.94%;
}

.span_3_of_12 {
  	width: 23.43%;
}

.span_2_of_12 {
  	width: 14.93%;
}

.span_1_of_12 {
  	width: 6.423%;
}



.span_8_of_12 .span_6_of_12 {
  width: 48.421%;
}

.span_9_of_12 .span_3_of_12 {
  width: 22.09%;
}
.span_9_of_12 .span_5_of_12 {
  width: 40.501%;
}
.span_9_of_12 .span_6_of_12 {
  width: 48.601%;
}
.span_9_of_12 .span_7_of_12 {
  width: 56.701%;
}



/*  GO FULL WIDTH BELOW 480 PIXELS */
@media only screen and (max-width: 480px) {
  .col {
    margin: 0;
  }

  .span_1_of_12, 
  .span_2_of_12,
  .span_3_of_12,
  .span_4_of_12,
  .span_5_of_12,
  .span_6_of_12,
  .span_7_of_12,
  .span_8_of_12,
  .span_9_of_12,
  .span_10_of_12,
  .span_11_of_12,
  .span_12_of_12 {
    width: 100%;
  }
}


/*
 * Hide visually and from screen readers
 */

.hidden {
    display: none !important;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 *
 * 1. For long content, line feeds are not interpreted as spaces and small width
 *    causes content to wrap 1 word per line:
 *    https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap; /* 1 */
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
    white-space: inherit;
}

/*
 * Hide visually and from screen readers, but maintain layout
 */

.invisible {
    visibility: hidden;
}

/*
 * Clearfix: contain floats
 *
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that receive the `clearfix` class.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.clearfix:before,
.clearfix:after,
.section:before,
.section:after,
.box:before,
.box:after,
.row:before,
.row:after {
	content: " "; /* 1 */
	display: table; /* 2 */
}

.clearfix:after,
.section:after,
.box:after,
.row:after {
	clear: both;
}

/* ==========================================================================
   EXAMPLE Media Queries for Responsive Design.
   These examples override the primary ('mobile first') styles.
   Modify as content requires.
   ========================================================================== */

@media only screen and (min-width: 35em) {
    /* Style adjustments for viewports that meet the condition */
}

@media print,
       (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 1.25dppx),
       (min-resolution: 120dpi) {
    /* Style adjustments for high resolution devices */
}

/* ==========================================================================
   Print styles.
   Inlined to avoid the additional HTTP request:
   https://www.phpied.com/delay-loading-your-print-css/
   ========================================================================== */

@media print {
    *,
    *:before,
    *:after {
        background: transparent !important;
        color: #000 !important; /* Black prints faster */
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration: underline;
    }

    a[href]:after {
        content: " (" attr(href) ")";
    }

    abbr[title]:after {
        content: " (" attr(title) ")";
    }

    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }

    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }
}
